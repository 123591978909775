$sidebarWidth: 336px;

.dashboard-main-wrapper {
  padding-top: 125px;
  padding-bottom: 70px;
  display: flex;
  background: url(../images/dashboard/Dashboardbanner.png);
  font-family: "Lato";
  @media (max-width: $tablet) {
    padding-top: 100px;
  }
}
.dashboard-sidebar-wrapper {
  flex: 0 0 $sidebarWidth;
  max-width: $sidebarWidth;
  @media (max-width: $macbook) {
    flex: 0 0 300px;
    max-width: 300px;
  }
  @media (max-width: $largeDesktop) {
    flex: 0 0 265px;
    max-width: 265px;
  }
  @media (max-width: $desktop) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.dashboard-content-wrapper {
  flex: 0 0 calc(100% - 336px);
  max-width: calc(100% - 336px);
  padding-left: 20px;
  @media (max-width: $macbook) {
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
  }
  @media (max-width: $largeDesktop) {
    flex: 0 0 calc(100% - 265px);
    max-width: calc(100% - 265px);
  }
  @media (max-width: $desktop) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }
}
.dashboard-content {
  background: #fff;
  padding: 30px;
  border-radius: 30px;
  @media (max-width: $macbook) {
    padding: 30px;
  }
  p {
    color: $black;
    @media (max-width: $tablet) {
      font-size: 14px;
    }
  }
  h2 {
    font-size: 22px;
    color: $grayDark;
    font-weight: bold;
    margin-bottom: 22px;
    @media (max-width: $macbook) {
      font-size: 20px;
    }
    @media (max-width: $desktop) {
      font-size: 18px;
    }
    @media (max-width: $tablet) {
      font-size: 16px;
    }
  }
  h4 {
    font-size: 28px;
    font-weight: 900;
    color: $grayDark;
    margin-bottom: 0;
    @media (max-width: $tablet) {
      font-size: 24px;
    }
  }
  .license-card {
    height: 100%;
    .card-body {
      @media (max-width: $largeDesktop) {
        padding: 20px;
      }
      @media (max-width: $tablet) {
        margin-bottom: 20px;
      }
      &.bg-pink {
        background: url(../images/dashboard/licence-pinkBg.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      &.bg-green {
        background: url(../images/dashboard/licence-greenBg.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      &.bg-blue {
        background: url(../images/dashboard/licence-blueBg.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .license-key-table {
    text-align: center;
    .card-body {
      padding: 0;
      background: url("../images/dashboard/dashboardTableBanner.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 30px;
    }
    table {
      &.table-bordered {
        border: 0 !important;
        td,
        th {
          vertical-align: middle;
          font-size: 14px;
        }
        td {
          border-top: 0 !important;
          font-size: 14px;
          color: rgba(51, 51, 51, 0.9);
          &:first-child {
            border-left: 0 !important;
          }
          &:last-child {
            border-right: 0 !important;
          }
        }
        tr {
          &:last-child {
            td {
              border-bottom: 0 !important;
            }
          }
        }
      }
      thead {
        th {
          background: rgb(255, 248, 217);
          border: 0 !important;
          &:first-child {
            border-top-left-radius: 30px;
          }
          &:last-child {
            border-top-right-radius: 30px;
          }
        }
      }
    }
    .active-info {
      button {
        font-size: 14px;
        font-weight: 600;
        color: rgb(32, 130, 253);
      }
    }
  }
  .buy-license-btn {
    height: 36px;
    width: 140px;
    @media (max-width: $phone) {
      width: auto;
      height: auto;
      font-size: 13px;
    }
  }
}
.sidebar-content {
  background: #fff;
  padding: 30px;
  border-radius: 30px;
  height: 100%;
  @media (max-width: $macbook) {
    padding: 30px 20px;
  }
  // @media (max-width: $desktop) {
  //   border-bottom-left-radius: 0;
  //   border-bottom-right-radius: 0;
  // }
  .sidebar-list {
    list-style: none;
    padding-left: 0;
    li {
      a {
        display: block;
        padding: 15px 20px;
        margin-bottom: 10px;
        color: rgba(51, 51, 51, 0.8);
        border-radius: 12px;
        text-decoration: none;
        position: relative;
        @media (max-width: $macbook) {
          padding: 10px 20px;
        }
        @media (max-width: $phone) {
          font-size: 14px;
        }
        &.active {
          background-image: linear-gradient(
            to right,
            rgba(255, 138, 67, 0.1),
            rgba(254, 29, 1, 0.1)
          );
          color: $orange;
          .menu-icon {
            img {
              &.img-1 {
                display: none;
              }
              &.img-2 {
                display: block;
              }
            }
          }
        }
        &:hover {
          background-image: linear-gradient(
            to right,
            rgba(255, 138, 67, 0.1),
            rgba(254, 29, 1, 0.1)
          );
          color: $orange;
          .menu-icon {
            img {
              &.img-1 {
                display: none;
              }
              &.img-2 {
                display: block;
              }
            }
          }
        }
        .menu-icon {
          position: relative;
          padding-left: 30px;
          img {
            margin-right: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            &.img-1 {
              display: block;
            }
            &.img-2 {
              display: none;
            }
          }
        }
      }
    }
  }
}
.dashboard-top-content {
  margin-bottom: 30px;
  align-items: center;
  // flex-wrap: wrap;
  flex: 0 0 calc(100% - 366px);
  max-width: calc(100% - 366px);
  margin-left: auto;
  @media (max-width: $macbook) {
    flex: 0 0 calc(100% - 320px);
    max-width: calc(100% - 320px);
  }
  @media (max-width: $largeDesktop) {
    flex: 0 0 calc(100% - 285px);
    max-width: calc(100% - 285px);
  }
  @media (max-width: $desktop) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: flex-start;
  }
  h2 {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 0;
    @media (max-width: $macbook) {
      font-size: 26px;
    }
    @media (max-width: $largeDesktop) {
      font-size: 22px;
    }
    @media (max-width: $desktop) {
      font-size: 20px;
    }
    @media (max-width: $tablet) {
      font-size: 18px;
    }
    @media (max-width: $phone) {
      font-size: 16px;
    }
  }
  .account-wrapper {
    display: flex;
    align-items: center;
    @media (min-width: $macbook) {
      margin-left: 40px;
    }
  }
  .user-account-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    li {
      padding: 0 25px;
      position: relative;
      font-weight: 600;
      display: flex;
      align-items: center;
      @media (max-width: $macbook) {
        font-size: 14px;
        padding: 0 20px;
      }

      &:not(:last-child) {
        span {
          position: relative;
          &:after {
            content: "";
            width: 1px;
            height: 100%;
            background: rgba(151, 151, 151, 0.5);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -18px;
            @media (max-width: $macbook) {
              right: -14px;
            }
            @media (max-width: $tablet) {
              right: -22px;
            }
          }
        }
      }
      button {
        display: flex;
        align-items: center;
        @media (max-width: $macbook) {
          font-size: 14px;
        }
      }
      .user {
        width: 40px;
        height: 40px;
        max-width: 100%;
        border-radius: 100%;
        @media (max-width: $macbook) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $tablet) {
      flex-direction: column;
      width: 100%;
    }
    input {
      width: 512px;
      border-radius: 20px;
      border: 0;
      height: 40px;
      outline: 0;
      padding: 10px 30px 10px 50px;
      background-color: #fff;
      background-image: url(../images/dashboard/search.png);
      background-repeat: no-repeat;
      background-position: left 20px center;
      @media (max-width: $macbook) {
        width: 330px;
        font-size: 14px;
      }
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
}
