.open-position-wrapper {
  font-family: Lato;
  .count {
    max-width: 268px;
    height: 254px;
    background: rgba(117, 117, 117, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    @media (max-width: $macbook) {
      height: 215px;
    }
    @media (max-width: $tablet) {
      margin: 0 auto 20px;
      max-width: 225px;
      height: 180px;
    }
    @media (max-width: $phone) {
      max-width: 190px;
      height: 160px;
    }
    h1 {
      font-size: 208px;
      font-family: "Bebas Neue";
      background: linear-gradient(to right, #ff8a43, #fe1d01);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;
      @media (max-width: $macbook) {
        font-size: 185px;
      }
      @media (max-width: $desktop) {
        font-size: 160px;
      }
      @media (max-width: $tablet) {
        font-size: 130px;
      }
      @media (max-width: $phone) {
        font-size: 110px;
      }
    }
  }
  .card {
    .card-body {
      padding: 64px;
      @media (max-width: $macbook) {
        padding: 50px;
      }
    }
  }

  .open-position-content {
    max-width: 340px;
    @media (max-width: $tablet) {
      margin: 0 auto;
      text-align: center;
    }
    h3 {
      font-size: 36px;
      font-weight: 700;
      max-width: 300px;
      margin-bottom: 20px;
      @media (max-width: $macbook) {
        font-size: 32px;
      }
      @media (max-width: $desktop) {
        font-size: 28px;
      }
      @media (max-width: $tablet) {
        margin: 0 auto 20px;
        font-size: 24px;
      }
      @media (max-width: $phone) {
        font-size: 20px;
      }
    }
    p {
      color: rgb(102, 102, 102);
      @media (max-width: $phone) {
        font-size: 14px;
      }
    }

    .explore-btn {
      width: 100%;
      margin: 20px 0;
      border-radius: 30px;
      text-transform: uppercase;
    }
  }
}
.about-us-wrapper {
  h3 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 32px;
    @media (max-width: $macbook) {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 26px;
    }
    @media (max-width: $desktop) {
      font-size: 28px;
      margin-bottom: 20px;
    }
    @media (max-width: $tablet) {
      font-size: 24px;
      margin-bottom: 10px;
    }
    @media (max-width: $phone) {
      font-size: 20px;
    }
  }
  .founder-image {
    position: relative;
    text-align: center;
    padding-right: 20px;
    &::after {
      @media (min-width: $tablet) {
        content: "";
        background: url("../images/commonImages/dots.png");
        width: 20px;
        height: 70px;
        position: absolute;
        right: -50px;
        background-repeat: no-repeat;
        bottom: -75px;
      }
    }
    img {
      max-width: 100%;
      position: relative;
      @media (min-width: $macbook) {
        width: 608px;
        height: 496px;
      }
      // @media (max-width: $tablet) {
      //   max-width: 400px;
      // }
      // @media (max-width: $phone) {
      //   max-width: 360px;
      // }
    }
  }
  .founder-detail {
    position: absolute;
    bottom: -42px;
    right: 0;
    width: 302px;
    background: linear-gradient(
      to right,
      rgba(#ff8a43, 0.85),
      rgba(#fe1d01, 0.85)
    );
    padding: 20px;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    @media (max-width: $tablet) {
      width: 270px;
      padding: 15px;
    }
    @media (max-width: $phone) {
      width: 240px;
      padding: 10px 15px;
    }
    span {
      border-left: 2px solid #fff;
      display: block;
      padding-left: 20px;
    }
    h4 {
      font-size: 22px;
      font-weight: 700;
      @media (max-width: $desktop) {
        font-size: 20px;
      }
      @media (max-width: $tablet) {
        font-size: 18px;
      }
      @media (max-width: $phone) {
        font-size: 14px;
      }
    }
    p {
      @media (max-width: $tablet) {
        font-size: 14px;
      }
      @media (max-width: $phone) {
        font-size: 12px;
      }
    }
  }
  .founder-info {
    @media (max-width: $largeDesktop) {
      margin-top: 100px;
      text-align: center;
    }
    @media (max-width: $desktop) {
      margin-top: 80px;
    }
    p {
      @media (max-width: $desktop) {
        font-size: 14px;
      }
    }
  }
}

.ca-position-wrapper {
  font-family: "Lato";
  padding-top: 0;
  @media (min-width: $desktop) {
    padding-top: 100px;
  }
  h3 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    @media (max-width: $macbook) {
      font-size: 32px;
    }
    @media (max-width: $tablet) {
      font-size: 28px;
      margin-bottom: 10px;
    }
    @media (max-width: $phone) {
      font-size: 24px;
    }
  }
  .subtext {
    max-width: 860px;
    margin: 0 auto 30px;
    @media (max-width: $phone) {
      font-size: 15px;
    }
  }
  .position-content-wrapper {
    padding: 5px;
  }
  .ca-position-content {
    background: rgb(246, 246, 246);
    width: 280px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    @media (max-width: $tablet) {
      width: 230px;
      height: 230px;
    }
    p {
      margin-bottom: 0;
      @media (max-width: $tablet) {
        font-size: 14px;
      }
    }
    hr {
      color: rgb(151, 151, 151);
      margin: 5px 0;
    }
    h3 {
      color: $orange;
      font-size: 20px;
      color: rgb(254, 35, 4);
      @media (max-width: $macbook) {
        font-size: 18px;
      }
      @media (max-width: $phone) {
        font-size: 16px;
      }
    }
  }
  .open-position {
    max-width: 1160px;
    margin: 0 auto;
  }
}
.workWithCAMAXWrapper {
  font-family: Lato;
  @media (max-width: $tablet) {
    padding-top: 0;
  }
  h3 {
    font-size: 36px;
    font-weight: 900;
    max-width: 425px;
    margin-bottom: 30px;
    @media (max-width: $macbook) {
      font-size: 32px;
    }
    @media (max-width: $desktop) {
      font-size: 26px;
      margin: 0 auto 30px;
      text-align: center;
    }
    @media (max-width: $tablet) {
      font-size: 24px;
    }
    @media (max-width: $phone) {
      font-size: 20px;
    }
    span {
      color: $orangeDark;
    }
  }
  .upload-files {
    width: 100%;
    border: 2px dashed rgba(96, 96, 96, 0.3);
    min-height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: 0;
    cursor: pointer;
    border-radius: 10px !important;
    margin: 30px 0;
    p {
      color: #000;
      margin-bottom: 0;
    }
  }
}
