body {
  margin: 0;
  color: #333333;
  font-family: "raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  @media (min-width: $macbook) {
    max-width: 1499px;
  }
}
section {
  padding: 60px 0;
}
.custom-title {
  height: 80px;
  color: #151514;
  font-family: "raleway";
  font-weight: 900;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: $macbook) {
    height: 60px;
    font-size: 28px;
  }
  @media (max-width: $desktop) {
    height: 50px;
    font-size: 25px;
  }
  @media (max-width: $tablet) {
    font-size: 20px;
  }
  .text-contain {
    height: 95%;
    position: relative;
    display: block;
    overflow: hidden;
  }

  .text {
    position: relative;
    display: inline-block;
  }
  .backText {
    top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 122px;
    color: rgba(#ff8a43, 0.2);
    white-space: nowrap;
    line-height: 1;
    @media (max-width: $macbook) {
      top: -10px;
      font-size: 100px;
    }
    @media (max-width: $desktop) {
      top: -10px;
      font-size: 80px;
    }
    @media (max-width: $tablet) {
      top: -5px;
      font-size: 50px;
    }
  }
}
.custom-white-title {
  height: 80px;
  color: #fff;
  font-family: "raleway";
  font-weight: 900;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: $macbook) {
    height: 60px;
    font-size: 28px;
  }
  @media (max-width: $desktop) {
    height: 50px;
    font-size: 25px;
  }
  @media (max-width: $tablet) {
    font-size: 20px;
  }
  .text-contain {
    height: 95%;
    position: relative;
    display: block;
  }

  .text {
    position: relative;
    display: inline-block;
    z-index: 2;
    &::after {
      content: "";
      background: url(../images/home/wavy.png);
    }
    // &:before {
    //   content: "";
    //   background: url(../images/home/white-triangle.png);
    //   width: 40px;
    //   height: 35px;
    //   position: absolute;
    //   top: -20px;
    //   left: -30px;
    // }
  }
  .backText {
    top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 122px;
    color: rgba(#ff8a43, 0.6);
    white-space: nowrap;
    line-height: 1;
    z-index: 1;
    height: 80px;
    overflow: hidden;
    @media (max-width: $macbook) {
      top: -10px;
      font-size: 100px;
    }
    @media (max-width: $desktop) {
      top: -10px;
      font-size: 80px;
    }
    @media (max-width: $tablet) {
      top: -5px;
      font-size: 50px;
    }
  }
}

.card {
  border-radius: 30px;
  border: 0;
  .card-body {
    border-radius: 30px;
    border: 0;
    padding: 30px;
  }
}
.input-group {
  position: relative;
  input {
    position: relative;
    &.form-control {
      border-radius: 10px !important;
      min-height: 46px;
      position: relative;
      background: rgba(204, 204, 204, 0.1);
      border: 1px solid #e4e4e4;
      @media (max-width: $macbook) {
        min-height: 40px;
      }
    }
  }
  img {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-right: 1px solid rgba(151, 151, 151, 0.5);
    padding-right: 10px;
  }
  &.icon-group {
    input {
      &.form-control {
        padding-left: 45px;

        &:focus,
        &:active,
        &:visited {
          padding-left: 12px;
          & + img {
            display: none;
          }
        }
      }
    }
    input[value]:not([value=""]) {
      padding-left: 12px;
      & + img {
        display: none;
      }
    }
  }
}
.required {
  color: red;
}

/*common btn*/
.renew-btn {
  background-image: linear-gradient(45deg, #facf4a, #f3a124) !important;
  border-radius: 20px !important;
  max-width: 140px;
  width: 100%;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  box-shadow: none;
  color: #fff;
  i {
    margin-left: 7px;
    font-size: 14px;
  }
}



.position-select {
  width: 100%;
  
  .position__control {
    height: 30px;
  border-radius: 10px !important;
  min-height: 46px;
  position: relative;
  background: rgba(204, 204, 204, 0.1);
  }
}

.pagination {
  justify-content: center;
  .page-item.active .page-link {
    
    background-color: #ff6d1f;
    border-color: #ff6d1f;
  }
  .page-link {
    
    color: #000000;
   
}
.page-item{
  border-bottom: 0 !important;
}
}