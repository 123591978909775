.about-wrapper {
  &.products {
    background: url(../images/products/Products.png);
    color: #fff;
  }
  p {
    color: $gray;
    @media (max-width: $desktop) {
      font-size: 14px;
    }
  }
  h3 {
    font-size: 22px;
    font-weight: 900;
    margin-top: 10px;
  }
  .custom-title {
    position: relative;
    &:before {
      content: "";
      background: url("../images/home/double-triangle.png");
      background-repeat: no-repeat;
      position: absolute;
      width: 20px;
      height: 30px;
      top: -27px;
      right: 43%;
    }
  }
}

.features-wrapper {
  background: url(../images/home/features-banner.png);
  background-size: cover;
  color: #fff;
  position: relative;
  transform: translateY(-90px);
  &:before {
    content: "";
    background: url(../images/home/line-circle-dark.png);
    position: absolute;
    right: 0;
    top: -66px;
    width: 280px;
    height: 175px;
    background-repeat: no-repeat;
  }
  &:after {
    content: "";
    background: url(../images/home/line-circle-light.png);
    position: absolute;
    left: 0;
    bottom: 130px;
    width: 280px;
    height: 230px;
    background-repeat: no-repeat;
  }
  .features-wrap {
    padding: 100px 0 0;
    .custom-white-title {
      .text {
        &:after {
          content: "";
          background: url("../images/home/wavy.png");
          background-repeat: no-repeat;
          width: 40px;
          height: 15px;
          position: absolute;
          bottom: -10px;
          right: -45px;
        }
        &:before {
          content: "";
          background: url("../images/home/white-triangle.png");
          background-repeat: no-repeat;
          width: 40px;
          height: 35px;
          position: absolute;
          top: -20px;
          left: -30px;
        }
      }
    }
  }
  .features-content {
    h3 {
      font-size: 22px;
      font-weight: 900;
      @media (max-width: $macbook) {
        font-size: 20px;
      }
      @media (max-width: $desktop) {
        font-size: 18px;
      }
    }
    p {
      @media (max-width: $desktop) {
        font-size: 14px;
      }
    }
    h1 {
      font-size: 100px;
      font-weight: 900;
      line-height: normal;
      color: rgb(255, 155, 97);
      font-family: "Lato";
      @media (max-width: $macbook) {
        font-size: 80px;
      }
      @media (max-width: $desktop) {
        font-size: 60px;
      }
      span {
        position: relative;
        &:after {
          content: "";
          background: url("../images/home/wavy.png");
          background-repeat: no-repeat;
          width: 40px;
          height: 15px;
          position: absolute;
          bottom: 0px;
          right: -15px;
        }
      }
    }
  }
  .feature-mobile-img {
    img {
      max-width: 200px;
      margin: 0 auto;
      margin-bottom: -70px;
      @media (max-width: $desktop) {
        margin-bottom: 20px;
      }
    }
  }
  .features-text {
    max-width: 435px;
    @media (max-width: $phone) {
      text-align: center;
    }
    @media (max-width: $desktop) {
      text-align: center;
      max-width: none;
      margin-bottom: 22px;
    }
  }
}
.features-content-wrapper {
  background: #fff;
  background-size: cover;
  @media (max-width: $desktop) {
    padding-top: 0;
  }
  .features-wrap {
    padding: 100px 0 0;
  }
  .features-content {
    h3 {
      font-size: 22px;
      font-weight: 900;
      @media (max-width: $macbook) {
        font-size: 20px;
      }
      @media (max-width: $desktop) {
        font-size: 18px;
      }
    }
    p {
      @media (max-width: $desktop) {
        font-size: 14px;
      }
    }
    h1 {
      font-size: 100px;
      font-weight: 900;
      line-height: normal;
      color: $orange;
      font-family: "Lato";
      margin-bottom: 0;
      @media (max-width: $macbook) {
        font-size: 80px;
      }
      @media (max-width: $desktop) {
        font-size: 60px;
      }
      span {
        position: relative;
        &:after {
          content: "";
          background: url("../images/home/red-wavy.png");
          background-repeat: no-repeat;
          width: 40px;
          height: 15px;
          position: absolute;
          bottom: 0px;
          right: -15px;
          @media (max-width: $desktop) {
            bottom: -8px;
            background-size: 25px;
          }
        }
      }
    }

    .phone-features {
      position: relative;
      text-align: center;
      img {
        @media (max-width: $tablet) {
          max-width: 200px;
          margin: 0 auto;
        }
        @media (max-width: $phone) {
          margin-bottom: 20px;
        }
      }
      &:after {
        content: "";
        background: url("../images/home/circle-square-small.png");
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 115px;
        transform: translate(50px, -60px);
        @media (max-width: $tablet) {
          content: none;
        }
      }
    }
  }
  .features-text {
    max-width: 435px;
    @media (max-width: $phone) {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.about-our-products-wrapper {
  background: rgba($orange, 0.04);
  position: relative;
  padding-bottom: 100px;
  &:before {
    @media (min-width: $tablet) {
      content: "";
      background: url("../images/home/our-product-circle.png");
      background-repeat: no-repeat;
      width: 160px;
      height: 165px;
      position: absolute;
      top: -109px;
      right: 0px;
      background-size: 100%;
    }
  }
  h3 {
    font-size: 22px;
    margin: 10px 0;
    font-weight: 700;
    @media (max-width: $macbook) {
      font-size: 20px;
    }
    @media (max-width: $desktop) {
      font-size: 18px;
    }
  }
  .custom-title {
    &:before {
      content: none !important;
    }
  }
  p {
    color: $gray;
    @media (max-width: $desktop) {
      font-size: 14px;
    }
  }
  .about-products-image {
    position: relative;
    &:after {
      content: "";
      background: url("../images/home/our-product-icon.png");
      width: 160px;
      height: 130px;
      position: absolute;
      top: -58px;
      right: 0px;
      background-size: 100%;
    }
    img {
      position: relative;
    }
    .image-text-wrapper {
      background: rgba(#fff, 0.5);
      padding: 15px;
      position: absolute;
      left: 0px;
      bottom: 34px;
      max-width: 300px;
    }
    .image-text {
      background: rgba(#fe2c0a, 0.8);
      color: #fff;
      width: 262px;
      padding: 7px 10px;
      p {
        color: #fff;
      }

      h4 {
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
  .custom-title {
    position: relative;
    &:before {
      content: "";
      background: url("../images/home/double-triangle.png");
      background-repeat: no-repeat;
      position: absolute;
      width: 20px;
      height: 30px;
      top: -27px;
      right: 0;
    }
  }
}
.business-trust-wrapper {
  h3 {
    font-size: 22px;
    font-weight: 900;
    margin-top: 10px;
    position: relative;
    margin-bottom: 70px;
    @media (max-width: $macbook) {
      margin-bottom: 60px;
      font-size: 20px;
    }
    @media (max-width: $desktop) {
      margin-bottom: 40px;
      font-size: 18px;
    }
    &:after {
      content: "";
      width: 40px;
      height: 2px;
      background: $orange;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 60px;
      @media (max-width: $macbook) {
        top: 50px;
      }
      @media (max-width: $desktop) {
        top: 40px;
      }
    }
  }
  .trust-wrapper {
    background: #fff;
    box-shadow: 0 3px 26px 0 rgba(230, 230, 230, 0.5);
    padding: 30px;
    @media (max-width: $desktop) {
      padding: 15px;
    }
    @media (max-width: $tablet) {
      margin-bottom: 20px;
    }
    p {
      color: $gray;
      @media (max-width: $desktop) {
        font-size: 14px;
      }
    }
  }
}
.top-brands-wrapper {
  background: rgb(236, 236, 236);
  img {
    max-width: 140px;
  }
  .custom-title {
    color: $orange;
    margin-bottom: 60px;
  }
  .backText {
    color: rgba(202, 202, 202, 0.2);
  }
}
.coming-soon-wrapper {
  font-family: Lato;
  @media (max-width: $tablet) {
    padding: 0;
    margin-bottom: 105px;
  }
  h3 {
    color: $orange;
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 0;
    @media (max-width: $macbook) {
      font-size: 16px;
    }
    @media (max-width: $desktop) {
      font-size: 14px;
    }
    @media (max-width: $phone) {
      font-size: 12px;
    }
  }
  h1 {
    @media (max-width: $macbook) {
      font-size: 30px;
    }
    @media (max-width: $desktop) {
      font-size: 28px;
    }
    @media (max-width: $tablet) {
      font-size: 24px;
    }
    @media (max-width: $phone) {
      font-size: 20px;
    }
  }
  p {
    max-width: 345px;
    @media (max-width: $tablet) {
      margin: 0 auto;
    }
  }
  img {
    @media (min-width: $macbook) {
      max-width: 420px;
    }
  }
  .pocket-text {
    span {
      background: linear-gradient(to right, #ff8a43, #fe1d01) !important;
      padding: 7px 50px;
      display: inline-block;
      margin-bottom: 15px;
      color: #fff;
      font-size: 29px;
      font-style: italic;
      font-weight: 900;
    }
  }

  .comming-soon-inputs {
    margin-top: 50px;
    margin-bottom: 20px;
    @media (max-width: $macbook) {
      margin-top: 40px;
    }
    @media (max-width: $desktop) {
      margin-top: 30px;
    }
    @media (max-width: $tablet) {
      margin-bottom: 30px;
    }
    .form-control {
      box-shadow: 0 3px 26px 0 rgba(230, 230, 230, 0.5);
      border: 0;
      max-width: 378px;
      padding: 10px 20px;
    }
    button {
      border-radius: 4px;
      min-width: 165px;
      text-transform: uppercase;
      @media (max-width: $macbook) {
        min-width: 140px;
      }
      @media (max-width: $desktop) {
        min-width: 110px;
      }
    }
  }
  .coming-soon-img {
    @media (max-width: $tablet) {
      text-align: center;
    }
    img {
      @media (max-width: $tablet) {
        max-width: 250px;
        margin: 0 auto 40px;
      }
    }
  }
  .stores-button {
    display: flex;
    align-items: center;
    background: #000;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    @media (max-width: $tablet) {
      max-width: 150px;
      margin: 10px auto 0;
    }
    p {
      font-size: 12px;
      color: #fff;
      margin: 0;
      width: 100 !important;
    }
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;
      margin: 0;
      @media (max-width: $desktop) {
        font-size: 14px;
      }
    }
  }
}
.testimonial-wrapper {
  font-family: "Lato";
  background: url(../images/home/testimonial-banner.png);
  background-repeat: no-repeat;
  background-position: bottom;
  .slick-arrow {
    background: rgba($orange, 0.2);
    width: 38px;
    height: 38px;
    border-radius: 100%;
    &:before {
      font-family: none;
      color: rgb(254, 29, 1);
      font-size: 32px;
      opacity: 1;
    }
    &.slick-next {
      &:before {
        right: 15px;
        position: relative;
      }
    }
    &.slick-prev {
      &:before {
        left: 15px;
        position: relative;
      }
    }
  }
  .slick-slide {
    padding: 0 60px;
  }
  .testimonial-slider {
    margin-top: 30px;
  }
  .testimonial-content {
    background: #fff;
    box-shadow: 0 3px 26px 0 rgba(230, 230, 230, 0.5);
    max-width: 387px;
    margin: 83px 0;
    padding: 28px;
    position: relative;
    &:after {
      content: "";
      background: url(../images/home/blockquotes.png);
      position: absolute;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      right: 15px;
      bottom: -20px;
    }
    img {
      border: 10px solid #fff;
      box-shadow: 0 3px 26px 0 rgba(230, 230, 230, 0.5);
      border-radius: 100%;
      margin-top: -85px;
    }
  }
  p {
    margin-bottom: 0;
    color: $gray;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
  }
}
