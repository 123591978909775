.admin-wrapper {
  display: flex;
  padding-top: 139px;
  .admin-sidebar {
    flex: 0 0 400px;
    max-width: 400px;
  }
  .admin-content-wrapper {
    flex: 0 0 calc(100% - 400px);
    max-width: calc(100% - 400px);
    padding: 30px;
  }
}
