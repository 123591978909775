.footer-wrapper {
  background: rgb(57, 57, 57);
  img {
    width: 70px;
  }
  .footer-detail {
    margin-bottom: 30px;
  }
  h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    @media (max-width: $tablet) {
      font-size: 16px;
    }
  }
  p,
  a {
    margin-bottom: 5px;
    color: rgb(152, 152, 152);
    @media (max-width: $tablet) {
      font-size: 14px;
    }
  }
  .col {
    &:first-child {
      @media (max-width: $tablet) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    @media (max-width: $phone) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.footer-detail {
  p {
    cursor: pointer;
  }
}
