.contact-form-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: url(../images/contact/contact-us-banner.png);
  background-size: contain;
  top: 0;
  padding-top: 167px;
  background-repeat: no-repeat;
  .contact-image {
    padding-top: 120px;
    padding-left: 40px;
    max-width: 100%;
  }
  .contact-us-form {
    font-family: Lato;
    @media (min-width: $macbook) {
      margin-bottom: 100px;
    }
    .card {
      box-shadow: 0 0 17px 0 rgba(221, 221, 221, 0.5);
      border-radius: 10px;
      .card-body {
        &:before {
          content: "";
          background: url("../images/contact/dots.png");
          width: 70px;
          height: 70px;
          position: absolute;
          right: -35px;
          background-repeat: no-repeat;
          top: -20px;
          @media (max-width: $desktop) {
            right: -10px;
          }
        }
        &:after {
          @media (min-width: $desktop) {
            content: "";
            background: url("../images/products/lines-circle.png");
            background-size: 100%;
            background-repeat: no-repeat;
            width: 200px;
            height: 200px;
            position: absolute;
            right: -90px;
            bottom: -87px;
          }
        }
      }
      h2 {
        font-size: 26px;
        font-weight: 900;
        color: rgb(51, 51, 51);
        margin-bottom: 30px;
      }
    }
    .form-control {
      border-radius: 10px;
      min-height: 46px;
      position: relative;
      background: rgba(#ccc, 0.1);
      border: 1px solid rgb(228, 228, 228);
      padding-left: 45px;
      &:focus {
        border: 1px solid $orange;
        box-shadow: none;
      }
    }
    label {
      font-size: 14px;
      color: rgba(51, 51, 51, 0.8);
      margin-bottom: 4px;
    }
    textarea {
      &.form-control {
        border-radius: 10px;
        min-height: 225px;
        padding-left: 12px;
      }
    }

    .services-wrapper {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      button {
        background: rgb(246, 246, 246);
        color: $gray;
        border: 1px solid #e4e4e4;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        // width: 161px;
        width: 100%;
        height: 44px;
        padding: 5px;

        margin-bottom: 10px;

        &:focus {
          border: 1px solid $orange;
          box-shadow: none !important;
          color: $orange;
          background: rgb(255, 246, 243);
        }
      }
    }
    .send-msg {
      margin-top: 40px;
      margin-bottom: 20px;
      button {
        width: 100%;
        height: 38px;
        text-transform: uppercase;
        font-size: 13px;
        padding: 10px 20px;
        max-width: 233px;
        @media (max-width: $phone) {
          font-size: 12px;
        }
      }
    }
  }

  .contact-details-wrapper {
    @media (max-width: $desktop) {
      text-align: center;
    }
    h3 {
      text-transform: uppercase;
      font-size: 20px;
      font-family: "Lato";
      @media (max-width: $macbook) {
        font-size: 18px;
      }
      @media (max-width: $desktop) {
        font-size: 16px;
      }
      @media (max-width: $tablet) {
        font-size: 14px;
      }
      @media (max-width: $phone) {
        font-size: 12px;
      }
    }
    h2 {
      font-weight: 900;
      font-size: 50px;
      position: relative;
      @media (max-width: $macbook) {
        font-size: 40px;
      }
      @media (max-width: $desktop) {
        font-size: 35px;
      }
      @media (max-width: $tablet) {
        font-size: 30px;
        margin-bottom: 30px;
      }
      @media (max-width: $phone) {
        font-size: 26px;
        margin-bottom: 20px;
      }

      span {
        color: $orange;
        position: relative;
        &:after {
          @media (min-width: $desktop) {
            content: "";
            background: url(../images/home/banner-circle.png);
            background-size: cover;
            background-repeat: no-repeat;
            width: 130px;
            height: 130px;
            position: absolute;
            right: -142%;
            bottom: -74px;
            z-index: 1;
          }
        }
      }
    }
    .contact-info {
      background: rgb(239, 239, 239);
      height: 164px;
      padding: 10px;
      text-align: center;
      font-family: "Lato";
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 206px;
      margin: 0 auto;
      img {
        margin-bottom: 10px;
      }
      p {
        color: rgb(88, 88, 88);
        margin-bottom: 0;
        font-size: 13px;
        word-break: break-word;
      }
      .contact-info-tile {
        color: #f44f1f !important;
      }
    }
  }
}
.google-map-wrapper {
  display: flex;
  padding-bottom: 0;
  @media (max-width: $phone) {
    padding-top: 16px;
  }
  .contact-image {
    width: 40%;
    padding-right: 10px;
    img {
      width: 100%;
      height: 616px;
      object-fit: cover;
    }
  }

  .google-map-content {
    width: 60%;
  }
}
.contact-mobile-image {
  width: 300px;
  margin: 20px auto;
  max-width: 100%;
  @media (min-width: $desktop) {
    display: none;
  }
  @media (max-width: $phone) {
    width: 230px;
  }
}
