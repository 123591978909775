
.verify-email-modal{
  .modal-content{
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    text-align: center;
    position:relative;
  }
  .closebtn{
    position: absolute;
      top: 10px;
      right: 14px;
      border: none;
      background: transparent;
      img{
        height:16px;
        width:16px;
      }
  }
  .modal-header {
    border: 0;
    .modal-title{
      color:#ff6d1f;
      font-weight:900;
    }
  }
  .modal-body{
    p{
      font-weight:600;
      span{
        color:#ff6d1f;
      }
    }
  }
}


