.btn {
  &-theme {
    background: linear-gradient(to right, #ff8a43, #fe1d01) !important;
    color: #fff !important;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    box-shadow: none !important;
  }
  &-bordered {
    border: 1px solid $orange !important;
    background: transparent !important;
    color: $orange !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
  }
  &-icon {
    border: 0 !important;
    background: transparent !important;
    padding: 0;
  }
}
