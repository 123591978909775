.download-card-wrapper {
  display: flex;
  font-family: "Lato";
  max-width: 690px;
  justify-content: space-between;
  margin: 40px auto 0;
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: center;
  }

  .download-card {
    box-shadow: 0 0 19px 0 rgba(232, 232, 232, 0.5);
    padding: 30px;
    text-align: center;
    max-width: 317px;
    border-radius: 15px;
    @media (max-width: $tablet) {
      margin-bottom: 20px;
    }
    h2 {
      font-size: 18px;
      font-weight: 900;
      max-width: 200px;
      margin: 0 auto 30px;
      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }
    p {
      display: flex;
      margin-bottom: 5px;
      font-size: 14px;
      label {
        color: rgb(51, 51, 51);
        text-decoration: underline;
        min-width: 85px;
        font-weight: 600;
      }

      span {
        color: rgb(255, 55, 55);
        text-decoration: underline;
        text-align: left;
      }
    }
    .download-user-btn {
      button {
        width: 100%;
      }
    }
  }
}
.download-details-wrapper {
  @media (max-width: $tablet) {
    padding-bottom: 0;
  }
  .custom-title {
    &:before {
      content: none;
    }
  }
}
.version-updation-wrapper {
  font-family: lato;
  @media (max-width: $tablet) {
    padding-top: 0;
  }
  .version-updation {
    background: url(../images/downloads/version-updation-wrapper.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding-bottom: 137px;
    .custom-title {
      margin-top: 150px;
      @media (max-width: $tablet) {
        margin-top: 200px;
      }
      .backText {
        color: rgba(202, 202, 202, 0.1);
      }
      .text {
        color: $orange;
      }
    }
  }

  h5 {
    color: $orange;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 900;
    margin-left: 60px;
    margin-bottom: 20px;
    @media(max-width: $tablet){
      margin-top: 20px
    }
    @media (max-width: $phone) {
      font-size: 18px;
    }
  }
  ul {
    list-style: none;
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 8px;
      font-weight: 500;
      @media (max-width: $phone) {
        font-size: 14px;
      }
      &:before {
        content: "";
        background: url(../images/downloads/right-arrow.png);
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
}
