.banner-wrapper {
  height: 840px;
  display: flex;
  align-items: center;
  position: relative;
  background: url(../images/home/banner.png);
  background-size: 100%;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  @media (max-width: $macbook) {
    height: auto;
    padding-top: 167px;
  }
  @media (max-width: $largeDesktop) {
    height: auto;
  }
  @media (max-width: $desktop) {
    height: auto;
  }
  @media (max-width: $tablet) {
    height: auto;
  }
  @media (max-width: $phone) {
    height: auto;
  }
  &:after {
    @media (min-width: $desktop) {
      content: "";
      background: url("../images/home/header-dots.png");
      background-repeat: no-repeat;
      width: 80px;
      height: 65px;
      position: absolute;
      top: 25px;
      right: 20px;
    }
  }
  &.products-wrapper {
    background: url(../images/products/Productsbanner.png);
    @media (max-width: $tablet) {
      height: auto;
    }
    @media (max-width: $phone) {
      height: auto;
    }

    .product-content {
      position: relative;
      @media (max-width: $tablet) {
        text-align: center;
      }

      &:after {
        content: "";
        background: url("../images/home/banner-circle.png");
        background-repeat: no-repeat;
        width: 215px;
        height: 215px;
        position: absolute;
        bottom: -221px;
        right: 100px;
        background-size: 55%;
      }

      h3 {
        span {
          position: relative;
          &:after {
            @media (min-width: $tablet) {
              content: "";
              background: url("../images/products/dots.png");
              background-repeat: no-repeat;
              width: 25px;
              height: 70px;
              position: absolute;
              top: -13px;
              right: -110px;
            }
          }
        }
      }
    }
  }

  &.download-wrapper {
    background: url(../images/downloads/Downloadsbanner.png);
    background-repeat: no-repeat;
    height: 920px;
    @media (max-width: $macbook) {
      height: auto;
    }
    @media (max-width: $phone) {
      padding-top: 167px;
    }

    &:after {
      content: none;
    }
    .download-btn {
      width: 100%;
      height: 46px;
      margin-top: 30px;
      font-family: "Lato";
    }
    .product-content {
      max-width: 320px;
      @media (max-width: $tablet) {
        text-align: center;
        margin: 0 auto;
      }
    
    }
    .download-link {
      border: 0;
      background: transparent;
      padding: 0;
      outline: 0;
      font-family: "Lato";
      text-decoration: underline;
      color: $orangeDark;
      margin-top: 10px;
    }
    .bottom-text {
      margin-top: 30px;
      p {
        color: #000;
        font-weight: 600;
        &.progress-text {
          width: 390px;
          @media (max-width: $phone) {
            width: auto;
          }
        }
      }
    }
   > a {
      text-decoration: none !important;
    }
    .stores-button {
      display: flex;
      align-items: center;
      background: #000;
      padding: 10px;
      border-radius: 6px;
      width: 100%;
      @media (max-width: $tablet) {
        max-width: 150px;
        margin: 10px auto 0;
      }
      p {
        font-size: 12px;
        color: #fff;
        margin: 0;
        width: 100 !important;
      }
      h4 {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        margin: 0;
        @media (max-width: $desktop) {
          font-size: 14px;
        }
      }
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 900;
    color: $orange;
    text-transform: uppercase;
    @media (max-width: $tablet) {
      font-size: 16px;
    }
    @media (max-width: $phone) {
      font-size: 14px;
    }
  }

  h1 {
    font-weight: 900;
    max-width: 500px;
    color: $black;
    margin-bottom: 20px;
    @media (max-width: $macbook) {
      font-size: 35px;
    }
    @media (max-width: $desktop) {
      font-size: 30px;
    }
    @media (max-width: $tablet) {
      margin: 0 auto 20px;
      font-size: 26px;
      max-width: 325px;
    }
    @media (max-width: $phone) {
      font-size: 22px;
    }
    span {
      color: $orange;
    }
  }
  p {
    color: $gray;
    font-size: 20px;
    max-width: 700px;
    @media (max-width: $tablet) {
      margin: 0 auto;
    }
  }
  .banner-buttons {
    margin-top: 30px;
    button {
      margin-right: 10px;
      height: 46px;
      width: 147px;
      @media (max-width: $macbook) {
        height: 42px;
        width: 134px;
      }
    }
  }
}
.banner-content {
  position: relative;
  @media (max-width: $tablet) {
    text-align: center;
  }
  &:after {
    content: "";
    background: url("../images/home/banner-circle.png");
    background-repeat: no-repeat;
    width: 213px;
    height: 210px;
    position: absolute;
    top: 39%;
    right: 35px;
    background-size: 45%;
  }
}


/**common**/
.read-link{
  text-decoration: underline !important;
  color: rgb(245, 44, 14);
  font-size: 13px;
  font-weight: bold;
}