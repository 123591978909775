.buy-license-modal {
 
  @media (min-width: $tablet) {
    // max-width: 998px;
    max-width: 700px;
  }
  .modal-close {
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0;
    position: absolute;
    right: 20px;
    top: 15px;
    img {
      width: 15px;
    }
  }
  .modal-header{
    padding: 0;
    border: 0;
  }
  .buy-license-wrapper {
    text-align: center;
    padding: 30px;
    h2 {
      font-size: 30px;
      font-weight: 900;
      @media (max-width: $macbook) {
        font-size: 26px;
      }
      @media (max-width: $desktop) {
        font-size: 24px;
      }
      @media (max-width: $tablet) {
        font-size: 20px;
      }
      @media (max-width: $desktop) {
        font-size: 18px;
      }
      span {
        color: $orange;
      }
    }
    .license-text {
      color: $gray;
      max-width: 320px;
      margin: 0 auto 80px;
      @media (max-width: $macbook) {
        margin-bottom: 40px;
      }
      @media (max-width: $desktop) {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    label {
      font-size: 14px;
      color: rgba(50, 50, 50, 0.7);
    }
    .form-control {
      border-color: rgb(223, 223, 223);
      &:focus {
        border: 1px solid $orange;
        box-shadow: none;
      }
    }
    .license-header {
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
      margin-bottom: 10px;
    }
    .buy-btn {
      margin-top: -25px;
      min-width: 100px;
    }
    .license-plan {
      box-shadow: 0 0 34px 0 rgba(230, 230, 230, 0.5);
      padding: 18px;
      border-radius: 22px;
      font-family: "Lato";
      min-height: 282px;

      &.active {
        background: url(../images/license-background.png);
        background-size: cover;
        margin-top: -40px;
        .license-header {
          .plans-name {
            color: #fff;
          }
          h4 {
            color: #fff;
            span {
              color: #fff;
            }
          }
        }
        ul {
          li {
            color: #fff;
            img {
              filter: brightness(25.5);
            }
          }
        }
      }
      h4 {
        font-size: 14px;
        color: $gray;
        span {
          font-size: 22px;
          font-weight: 600;
          color: $black;
        }
      }
      .plans-name {
        color: $orange;
        font-weight: 900;
        margin-bottom: 0;
      }
      ul {
        list-style: none;
        padding-left: 0;
        text-align: left;
        li {
          font-size: 14px;
          color: $gray;
          display: flex;
          align-items: flex-start;
          padding-left: 25px;
          position: relative;
          img {
            position: absolute;
            top: 7px;
            left: 3px;
          }
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 37px;
        height: 22px;
        margin-bottom: 18px;
        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked {
            & + .slider {
              background-color: #fff;
              // border: 1px solid $orange;
              &:before {
                transform: translateY(-50%) translateX(14px);
                background: #fff;
                left: 4px;
              }
              &.round {
                &:before {
                  left: 4px;
                }
              }
            }
            & ~ .text {
              &.multiple {
                color: $orangeDark;
              }
            }
          }
          &:not(:checked) {
            & ~ .text {
              &.single {
                color: $orangeDark;
              }
            }
          }
          // &:focus {
          //   & + .slider {
          //     box-shadow: 0 0 1px #2196f3;
          //   }
          // }
        }
        .text {
          position: absolute;
          left: 100%;
          white-space: nowrap;
          margin-left: 10px;
          color: rgba(51, 51, 51, 0.8);
          font-weight: 600;
          @media (max-width: $desktop) {
            font-size: 13px;
          }
          &.single {
            position: absolute;
            left: -90px;
          }
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          to right,
          rgba(#ff8a43, 0.85),
          rgba(#fe1d01, 0.85)
        );
        // border: 1px solid #c5c5c5;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: "";
          height: 17px;
          width: 17px;
          left: 2px;
          bottom: 1px;
          background-color: #fff;
          transition: 0.4s;
          top: 50%;
          transform: translateY(-50%);
        }
        &.round {
          border-radius: 34px;
          &:before {
            border-radius: 50%;
            background: #fff;
          }
        }
      }
    }
    .save-btn {
      min-width: 153px;
      text-transform: uppercase;
      margin-top: 60px;
    }
  }
}

.confirmation-modal {
  h3 {
    font-size: 20px;
    text-align: center;
    @media (max-width: $desktop) {
      font-size: 18px;
    }
    @media (max-width: $phone) {
      font-size: 16px;
    }
  }
  .confirmation-wrapper {
    @media (min-width: $phone) {
      padding: 20px;
    }

    button {
      margin-top: 15px;
      @media (max-width: $phone) {
        font-size: 12px;
      }
    }
  }
}


.confirmation-modal {
  .modal-content {
    border-radius: 10px;
    padding: 30px 0px;
    .modal-body {
      padding: 0;
      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .btn-theme {
      max-width: 110px;
      width: 100%;
      padding: 9px 5px;
      font-weight: 700;
      font-size: 15px;
  }
  }
  }
}