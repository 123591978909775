$gray: rgba(51, 51, 51, 0.6);
$grayDark: rgba(51, 51, 51, 0.8);
$black: #000;
$orange: #ff6d1f;
$orangeDark: #fe2304;

$macbook: 1440px;
$largeDesktop: 1199.98px;
$desktop: 991.98px;
$tablet: 767.98px;
$phone: 575.98px;
