.employee-wrapper {
  background: url(../images/commonImages/employee-banner.png);
  padding-top: 167px;
  background-repeat: no-repeat;
  @media (max-width: $tablet) {
    padding-top: 167px;
  }
}

.employee-details-wrapper {
  h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-family: "Lato";
    @media (max-width: $macbook) {
      font-size: 18px;
    }
    @media (max-width: $desktop) {
      font-size: 16px;
    }
    @media (max-width: $tablet) {
      font-size: 14px;
    }
    @media (max-width: $phone) {
      font-size: 12px;
    }
  }
  h2 {
    font-weight: 900;
    font-size: 50px;
    position: relative;
    margin-bottom: 40px;
    @media (max-width: $macbook) {
      font-size: 40px;
    }
    @media (max-width: $desktop) {
      font-size: 35px;
    }
    @media (max-width: $tablet) {
      font-size: 30px;
      margin-bottom: 30px;
    }
    @media (max-width: $phone) {
      font-size: 26px;
      margin-bottom: 20px;
    }
    span {
      color: $orange;
      position: relative;
      &:after {
        @media (min-width: $desktop) {
          content: "";
          background: url(../images/home/banner-circle.png);
          background-size: cover;
          background-repeat: no-repeat;
          width: 130px;
          height: 130px;
          position: absolute;
          right: -200px;
          bottom: -74px;
          z-index: 1;
        }
      }
    }
  }
}
.employee-form-wrapper {
  font-family: Lato;
  margin-bottom: 100px;
  position: relative;
  @media (max-width: $tablet) {
    padding: 0 20px;
  }
  @media (max-width: $phone) {
    padding: 0 10px;
  }
  &:after {
    content: "";
    background: url("../images/contact/dots.png");
    width: 70px;
    height: 70px;
    position: absolute;
    left: 80px;
    background-repeat: no-repeat;
    bottom: -40px;
    opacity: 0.2;
  }
  .card {
    box-shadow: 0 0 17px 0 rgba(221, 221, 221, 0.5);
    border-radius: 15px;
    .card-body {
      &:before {
        content: "";
        background: url("../images/contact/dots.png");
        width: 70px;
        height: 70px;
        position: absolute;
        right: -35px;
        background-repeat: no-repeat;
        top: -20px;
        @media (max-width: $tablet) {
          right: -10px;
        }
      }
      &:after {
        @media (min-width: $macbook) {
          content: "";
          background: url("../images/products/lines-circle.png");
          background-size: 100%;
          background-repeat: no-repeat;
          width: 200px;
          height: 200px;
          position: absolute;
          right: -90px;
          bottom: -87px;
        }
      }
    }

    h2 {
      font-size: 26px;
      font-weight: 900;
      color: rgb(51, 51, 51);
      margin-bottom: 30px;
      @media (max-width: $macbook) {
        font-size: 22px;
      }
      @media (max-width: $tablet) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  textarea {
    border-radius: 10px !important;
  }
  .form-control {
    border-radius: 10px;
    min-height: 46px;
    position: relative;
    background: rgba(#ccc, 0.1);
    border: 1px solid rgb(228, 228, 228);
    @media (max-width: $macbook) {
      min-height: 40px;
    }
    &:focus {
      border: 1px solid $orange;
      box-shadow: none;
    }
  }
  label {
    font-size: 14px;
    color: rgba(51, 51, 51, 0.8);
    margin-bottom: 4px;
  }
  .employee-submit-button {
    min-width: 100px;
    margin-top: 10px;
  }
}
.recent-jobs-post-wrapper {
  h2 {
    font-size: 22px;
    color: rgba(51, 51, 51, 0.9);
    font-weight: 900;
    position: relative;
    margin-bottom: 67px;
    @media (max-width: $macbook) {
      font-size: 20px;
      margin-bottom: 50px;
    }
    @media (max-width: $desktop) {
      margin-bottom: 40px;
    }
    @media (max-width: $tablet) {
      font-size: 18px;
      margin-bottom: 28px;
    }
    &:before {
      content: "";
      height: 1px;
      width: 100%;
      background: rgba(151, 151, 151, 0.5);
      position: absolute;
      bottom: 3px;
    }
    span {
      background: #fff;
      position: relative;
      padding-right: 15px;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    font-family: Lato;
    li {
      margin-bottom: 46px;
      h3 {
        font-size: 22px;
        font-weight: 600;
        @media (max-width: $macbook) {
          font-size: 20px;
        }
        @media (max-width: $tablet) {
          font-size: 18px;
        }
      }
      p {
        color: rgb(102, 102, 102);
      }
      &:not(:last-child) {
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        padding-bottom: 46px;
      }
    }
  }
  .display-text-group {
    .displayed-text {
      font-size: 14px;
    }
  }
  .accountant-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;
    flex-wrap: wrap;
    @media (max-width: $macbook) {
      padding: 14px 0;
    }
    .accountant-content {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      @media (max-width: $phone) {
        flex-grow: 1;
      }
      img {
        margin-right: 10px;
        @media (max-width: $macbook) {
          margin-right: 6px;
          width: 18px;
        }
      }
      p {
        margin-bottom: 0;
        @media (max-width: $macbook) {
          font-size: 14px;
        }
      }
    }
  }
  .read-more-button {
    color: $orangeDark;
    text-transform: capitalize;
  }
  .apply-btn {
    width: 141px;
    height: 43px;
    border-radius: 30px;
    text-transform: uppercase;
    margin-top: 10px;
  }
}
