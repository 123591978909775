.mobile-menu-wrapper {
  @media (max-width: $desktop) {
    position: fixed;
    right: -600px;
    width: 300px;
    transition: all 0.1s;
    top: 0;
    bottom: 0;
  }
  &.isVisible {
    right: 0;
    .backdrop {
      position: fixed;
      background: rgba(0, 0, 0, 0.7);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    .mobile-menu-content {
      padding: 30px;
      width: 300px;
      height: 100vh;
      z-index: 999;
      background: #fff;
      color: #000;
      position: relative;
      li {
        margin-bottom: 10px;
      }
      .close {
        position: absolute;
        top: 15px;
        left: 20px;
        img {
          width: 15px;
        }
      }
    }
  }
}
.mobile-menu-content {
  .close {
    @media (min-width: $desktop) {
      display: none;
    }
  }
}

.nav-bar {
  display: flex;
  margin-bottom: 0;
  @media (max-width: $desktop) {
    flex-direction: column;
    padding-top: 50px;
  }
  .btn {
    padding: 0;
    margin-right: 30px;
    outline: none;
    box-shadow: none !important;
    @media (max-width: $largeDesktop) {
      margin-right: 10px;
    }
    &.active {
      color: $orange;
    }
  }
}
.main {
  margin-top: 100px;
}
header {
  z-index: 99;
  position: fixed;
  width: 100%;
  padding: 15px;
  background: #fff;
  box-shadow: 0 0 6px #ccc;
  &.transparent-header {
    background: transparent;
    box-shadow: none;
    & + .main {
      margin-top: 0;
    }
  }
  &.sticky-top {
    & + .main {
      margin-top: 0;
    }
  }
}
.poweredBy {
  margin-top: 10px;
  font-size: 11px;
  width: 130px;
  text-align: inherit;
}
.logo {
  margin-right: 30px;

  img {
    height: 65px;
    @media (max-width: $tablet) {
      height: 50px;
    }
  }
}
.mobile-menu-btn {
  background: transparent;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
}
